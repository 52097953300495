// Inform state when the browser goes offline/online
type ConnectivtyChangeCallbackType = (isOnline: boolean) => void

export function observeConnectivity(callbackFn: ConnectivtyChangeCallbackType) {
  window.addEventListener('online', () => {
    callbackFn(true)
  })

  window.addEventListener('offline', () => {
    callbackFn(false)
  })
}
